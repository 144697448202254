import { gsap } from 'gsap'

const headingBg = () => {
  const bg = document.getElementsByClassName('heading__bg')

  for (let i = 0; i < bg.length; i++) {
    gsap
      .timeline({
        repeat: -1,
      })
      .to(bg[i], {
        xPercent: -50,
        duration: 50,
        ease: 'linear',
      })

    const wrap = bg[i].querySelector('.heading__bg-text-wrap')

    gsap.to(wrap, {
      xPercent: -5,
      ease: 'linear',
      scrollTrigger: {
        trigger: bg[i],
        start: 'top bottom',
        end: 'bottom top',
        scrub: 0.5,
      },
    })
  }
}

export default headingBg
