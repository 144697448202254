import Swiper, { Autoplay, EffectFade } from 'swiper'

const visionSlide = () => {
  new Swiper('.swiper.vision-slide', {
    modules: [Autoplay, EffectFade],
    loop: true,
    autoplay: {
      delay: 5000,
    },
    effect: 'fade',
    speed: 1000,
    allowTouchMove: false,
  })
}

export default visionSlide
