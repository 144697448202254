import Lenis from '@studio-freight/lenis'

const smoothScroll = () => {
  const lenis = new Lenis()

  const ref = (time) => {
    lenis.raf(time)
    requestAnimationFrame(ref)
  }

  lenis.duration = 1.0
  lenis.easing = (t) => {
    return t === 1 ? 1 : 1 - Math.pow(2, -10 * t)
  }

  requestAnimationFrame(ref)
}

export default smoothScroll
