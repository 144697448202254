import Swiper, { Autoplay, EffectFade } from 'swiper'
import { gsap } from 'gsap'
import * as THREE from 'three'

const opening = () => {
  const slider = new Swiper('.swiper.hero-slide', {
    modules: [Autoplay, EffectFade],
    loop: true,
    autoplay: {
      delay: 5000,
    },
    effect: 'fade',
    speed: 1000,
    allowTouchMove: false,
  })

  const el = document.getElementsByClassName('opening')[0]

  const animation = () => {
    window.addEventListener('load', () => {
      const body = document.getElementsByTagName('body')[0]
      body.classList.add('is-fixed')
      slider.autoplay.stop()
      // sessionStorage.setItem('visited', true)

      gsap
        .timeline()
        .to(
          el,
          {
            autoAlpha: 0,
            duration: 0.5,
            ease: 'quart.in',
            onComplete: () => {
              body.classList.remove('is-fixed')
            },
          },
          '<0.4'
        )
        .from('.hero__img .swiper-wrapper', {
          scale: 1.25,
          opacity: 0,
          duration: 1.8,
          ease: 'quad.out',
          onComplete: () => {
            slider.autoplay.start()
          },
        })
        .from(
          '.site-header__logo',
          {
            autoAlpha: 0,
            y: -50,
            scaleY: 2,
            duration: 1,
            ease: 'expo.out',
          },
          '<0.6'
        )
        .from(
          '.header-nav__item',
          {
            autoAlpha: 0,
            y: -50,
            scaleY: 2,
            duration: 1,
            ease: 'expo.out',
            stagger: 0.15,
          },
          '<0.1'
        )
        .from(
          '.hamburger-btn__inner',
          {
            autoAlpha: 0,
            y: -50,
            scaleY: 2,
            duration: 1,
            ease: 'expo.out',
          },
          '<0.2'
        )
        .from(
          '.floating-btn-container',
          {
            xPercent: 100,
            autoAlpha: 0,
            duration: 1.0,
            ease: 'expo.out',
          },
          '<0.1'
        )
        .from(
          '.catch-copy > *',
          {
            autoAlpha: 0,
            scaleY: 2,
            y: 80,
            duration: 1.4,
            ease: 'expo.out',
            stagger: 0.3,
          },
          '<0.1'
        )
    })
  }

  if (location.href.replace(location.origin, '') !== '/') {
    gsap.set(el, { autoAlpha: 0 })
  } else {
    animation()
  }
}

export default opening
