import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { textSplit } from './libs'

gsap.registerPlugin(ScrollTrigger)

const heading = () => {
  const heading = document.getElementsByClassName('js-heading')

  ;[...heading].forEach((target) => {
    const num = target.querySelector('.heading__num')
    const line = target.querySelector('.heading__num-line')
    const main = target.querySelector('.heading__title-main')
    const sub = target.querySelector('.heading__title-sub')
    const subChars = textSplit(sub)

    gsap
      .timeline({
        scrollTrigger: {
          trigger: target,
          start: 'top 75%',
        },
      })
      .from(line, {
        scaleX: 0,
        duration: 1.2,
        ease: 'expo.out',
      })
      .from(
        num,
        {
          yPercent: 150,
          duration: 1.0,
          ease: 'expo.out',
        },
        '<0.2'
      )
      .from(
        num,
        {
          scaleY: 2,
          duration: 1.0,
          ease: 'circ.out',
        },
        '<'
      )
      .from(
        main,
        {
          yPercent: 150,
          duration: 1.0,
          ease: 'expo.out',
        },
        '<0.4'
      )
      .from(
        main,
        {
          scaleY: 2,
          duration: 1.0,
          ease: 'circ.out',
        },
        '<'
      )
      .from(
        subChars,
        {
          autoAlpha: 0,
          xPercent: 100,
          duration: 0.7,
          ease: 'expo.out',
          stagger: {
            each: 0.07,
            ease: 'quad.in',
          },
        },
        '<0.2'
      )
  })
}

export default heading
