import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const parallax = () => {
  const els = document.querySelectorAll('.js-parallax')

  gsap.set(els, {
    overflow: 'hidden',
  })

  els.forEach((target) => {
    const img = target.querySelector(':scope > *')
    gsap.set(img, { scale: 1.2 })
    const diff =
      target.getBoundingClientRect().height - img.getBoundingClientRect().height

    gsap.fromTo(
      img,
      {
        yPercent: (100 / 1.2 - 100) / 2,
      },
      {
        yPercent: -(100 / 1.2 - 100) / 2,
        ease: 'linear',
        scrollTrigger: {
          trigger: target,
          scrub: 0.5,
          start: 'top bottom',
          end: 'bottom top',
        },
      }
    )
  })
}

export default parallax
