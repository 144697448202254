const { gsap } = require('gsap')

const headerMenu = () => {
  const link = document.getElementsByClassName('header-nav__link')

  ![...link].forEach((target) => {
    const word = target.getElementsByClassName('word')
    const line = target.getElementsByClassName('header-nav__line')[0]

    target.addEventListener('mouseenter', () => {
      gsap.timeline().fromTo(
        word,
        {
          yPercent: 0,
        },
        {
          yPercent: -100,
          duration: 0.8,
          ease: 'quint.inOut',
        }
      )

      gsap
        .timeline()
        .fromTo(
          word,
          {
            scaleY: 1,
          },
          {
            scaleY: 2,
            duration: 0.4,
            ease: 'quint.in',
          }
        )
        .to(word, {
          scaleY: 1,
          duration: 0.4,
          ease: 'quint.out',
        })

      gsap
        .timeline()
        .fromTo(
          line,
          {
            xPercent: 0,
          },
          {
            xPercent: 100,
            duration: 0.4,
            ease: 'cubic.in',
          }
        )
        .fromTo(
          line,
          {
            xPercent: -100,
          },
          {
            xPercent: 0,
            duration: 1.0,
            ease: 'quint.out',
          }
        )
    })
  })
}

export default headerMenu
