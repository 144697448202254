import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

const scalein = () => {
  const els = document.getElementsByClassName('media-oblong__img')

  ;[...els].forEach((target) => {
    const img = target.querySelector('img')

    gsap.from(img, {
      scale: 1.3,
      opacity: 0,
      duration: 1.4,
      ease: 'circ.out',
      scrollTrigger: {
        trigger: target,
        start: 'top 75%',
      },
    })
  })
}

export default scalein
