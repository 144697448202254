import { gsap } from 'gsap'

const hamburgerMenu = () => {
  const spMenu = document.getElementById('sp-menu')
  const hamburgerButton = document.getElementById('hamburger-btn')
  const body = document.getElementsByTagName('body')[0]
  const spMenuItem = document.getElementsByClassName('sp-nav__item')
  const othersItem = document.getElementsByClassName('sp-others__item')
  const bg = document.getElementsByClassName('sp-menu__bg')[0]

  let state = false

  const open = () => {
    gsap
      .timeline()
      .set(hamburgerButton, {
        pointerEvents: 'none',
      })
      .set(spMenu, {
        visibility: 'visible',
      })
      .to(bg, {
        scale: 1,
        duration: 0.6,
        ease: 'expo.out',
      })
      .fromTo(
        spMenuItem,
        {
          autoAlpha: 0,
          y: 20,
        },
        {
          autoAlpha: 1,
          y: 0,
          duration: 0.9,
          ease: 'expo.out',
          stagger: 0.05,
        },
        '<0.15'
      )
      .fromTo(
        othersItem,
        {
          autoAlpha: 0,
          y: 50,
        },
        {
          autoAlpha: 1,
          y: 0,
          duration: 0.9,
          ease: 'expo.out',
          stagger: 0.05,
          onComplete: () => {
            gsap.set(hamburgerButton, { pointerEvents: 'auto' })
          },
        },
        '<0.28'
      )
  }

  const close = () => {
    gsap
      .timeline()
      .set(hamburgerButton, { pointerEvents: 'none' })
      .to(spMenuItem, {
        autoAlpha: 0,
        duration: 0.6,
        ease: 'circ.out',
      })
      .to(
        othersItem,
        {
          autoAlpha: 0,
          duration: 0.6,
          ease: 'circ.out',
        },
        '<'
      )
      .to(
        bg,
        {
          scale: 0,
          duration: 0.7,
          ease: 'expo.out',
          onComplete: () => {
            gsap.set(spMenu, {
              visibility: 'hidden',
            })
            gsap.set(hamburgerButton, { pointerEvents: 'auto' })
          },
        },
        '<0.2'
      )
  }

  hamburgerButton.onclick = () => {
    hamburgerButton.classList.toggle('is-open')
    spMenu.classList.toggle('is-show')
    body.classList.toggle('is-fixed')
  }
  ;[...spMenuItem].forEach((item) => {
    item.addEventListener('click', (e) => {
      const link = e.target.href
      if (location.pathname === '/' && link.search('#')) {
        e.preventDefault()
        hamburgerButton.classList.remove('is-open')
        spMenu.classList.remove('is-show')
        body.classList.remove('is-fixed')
        state = !state
        close()
        location.href = link
      }
    })
  })

  hamburgerButton.addEventListener('click', () => {
    state = !state
    state ? open() : close()
  })
}

export default hamburgerMenu
