export const textSplit = (el) => {
  const nodes = el.childNodes

  let spanWrapText = ''

  nodes.forEach((node) => {
    node.nodeType === 3
      ? (spanWrapText += node.textContent.split('').reduce((prev, current) => {
          return current === ' '
            ? prev + `<span>&nbsp;</span>`
            : prev + `<span class="char">${current}</span>`
        }, ''))
      : (spanWrapText += node.outerHTML)
  })

  el.innerHTML = spanWrapText

  return el.querySelectorAll('.char')
}
