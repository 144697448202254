import { gsap } from 'gsap'
import hamburgerMenu from './modules/hamburger-menu'
import headerMenu from './modules/header-menu'
import heading from './modules/heading'
import headingBg from './modules/heading-bg'
import opening from './modules/opening'
import parallax from './modules/parallax'
import scalein from './modules/scalein'
import smoothScroll from './modules/smooth-scroll'
import visionSlide from './modules/vision-slide'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

gsap.config({
  nullTargetWarn: false,
})

gsap.registerPlugin(ScrollToPlugin)

const scrollto = () => {
  if (location.pathname === '/') {
    const link = document.querySelectorAll('a[href*="#"]')

    link.forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault()

        const attr = el.getAttribute('href')
        const hash = attr.split('#')

        gsap.to(window, {
          scrollTo: `#${hash[1]}`,
          duration: 1,
          ease: 'quint.inOut',
        })
      })
    })
  }
}

headerMenu()
smoothScroll()
heading()
headingBg()
scalein()
parallax()
visionSlide()
hamburgerMenu()
opening()
scrollto()
